import requestInterval, { clearRequestInterval } from '../utils/requestInterval'

const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
}

const validateHex = (hex) => hex && /^#([0-9A-F]{3}){1,2}$/i.test(hex)

const scrollSpy = function (container) {
  this.node = container

  this.spy = () => {
    this.elementsInMiddleOfScreen = document.elementsFromPoint(
      window.innerWidth / 2,
      window.innerHeight / 2
    )

    // now check to see if whats in the middle of the screen is one of the text boxes
    this.boxes.forEach((box) => {
      this.elementsInMiddleOfScreen.forEach(($elAtPoint) => {
        if ($elAtPoint === box) {
          this.currentVisibleId = [box.id, box.dataset.id]
          if (validateHex(box.dataset.backgroundColor)) {
            this.background &&
              (this.background.style.backgroundColor =
                'var(--color__background)')
            document.documentElement.style.setProperty(
              '--color__background',
              box.dataset.backgroundColor
            )
          }
        }
      })
    })
  }

  this.updated = () => {
    this.background && (this.background.style.backgroundColor = '')
    document.documentElement.style.removeProperty('--color__background')
  }

  this.init = () => {
    this.currentVisibleId = []
    this.elementsInMiddleOfScreen = []
    this.background =
      document.querySelector('[data-scrollSpy-background]') ||
      document.documentElement
    this.boxes = this.node.querySelectorAll('[data-scrollSpy-box]')
    this.updated = this.updated.bind(this)

    document.addEventListener('page:updated', this.updated)

    this.fM = requestInterval(this.spy, 60)
  }

  this.destroy = () => {
    if (this.fM) {
      clearRequestInterval(this.fM)
    }
  }
}

export default scrollSpy
