// https://css-tricks.com/snippets/javascript/replacements-setinterval-using-requestanimationframe/
// https://gist.github.com/joelambert/1002116#gistcomment-1953925

const requestInterval = function(fn, delay) {
  var requestAnimFrame = (function() {
      return (
        window.requestAnimationFrame ||
        function(callback, element) {
          window.setTimeout(callback, 1000 / 60);
        }
      );
    })(),
    start = new Date().getTime(),
    handle = {};
  function loop() {
    handle.value = requestAnimFrame(loop);
    var current = new Date().getTime(),
      delta = current - start;
    if (delta >= delay) {
      fn.call();
      start = new Date().getTime();
    }
  }
  handle.value = requestAnimFrame(loop);
  return handle;
};

export const clearRequestInterval = function(handle) {
  if (typeof handle !== 'undefined' && handle.value) {
    window.cancelAnimationFrame
      ? window.cancelAnimationFrame(handle.value)
      : window.webkitCancelAnimationFrame
      ? window.webkitCancelAnimationFrame(handle.value)
      : window.webkitCancelRequestAnimationFrame
      ? window.webkitCancelRequestAnimationFrame(
          handle.value
        ) /* Support for legacy API */
      : window.mozCancelRequestAnimationFrame
      ? window.mozCancelRequestAnimationFrame(handle.value)
      : window.oCancelRequestAnimationFrame
      ? window.oCancelRequestAnimationFrame(handle.value)
      : window.msCancelRequestAnimationFrame
      ? window.msCancelRequestAnimationFrame(handle.value)
      : clearInterval(handle);
  }
};

export default requestInterval;
