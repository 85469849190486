import requestInterval, { clearRequestInterval } from '../utils/requestInterval'

const smoothScrollTo = function (container) {
  this.node = container

  this.handleClick = (e) => {
    e.preventDefault()
    const id = this.node.dataset.scrollTo

    if (id) {
      const el = document.getElementById(id)

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    }
  }

  this.handleClick = this.handleClick.bind(this)

  this.init = () => {
    this.node.addEventListener('click', this.handleClick)
  }

  this.destroy = () => {}
}

export default smoothScrollTo
