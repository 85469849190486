import {
  manageBehaviors,
  resized,
  triggerCustomEvent,
} from '@area17/a17-helpers'
import * as Behaviors from './behaviors'
import * as Barba from './libs/barba'
import analytics from './utils/analytics'
import { updateHeader } from './utils/updateHeader'
import { manageTransitions } from './utils/manageTransitions'
import AOS from 'aos'
/*

  A17

  Doc: // Doc: https://code.area17.com/a17/fe-boilerplate/wikis/js-app

*/

A17.latestClickElement = null
A17.sections = []
A17.navbar = false
A17.scrolling = false
A17.beforeSearchContent = null
A17.searched = false

// HTML4 browser?
if (!A17.browserSpec || A17.browserSpec === 'html4') {
  // lets kill further JS execution of A17 js here
  throw new Error('HTML4')
}

document.addEventListener('DOMContentLoaded', function () {
  // Barba JS options
  var ignoreLinksClass = 'no-pjax'
  Barba.Pjax.ignoreClassLink = ignoreLinksClass
  Barba.Pjax.Dom.wrapperId = 'pjax'
  Barba.Pjax.Dom.wrapperDefaultId = Barba.Pjax.Dom.wrapperId
  Barba.Pjax.Dom.wrapperAttr = 'data-pjax-wrapper'
  Barba.Pjax.Dom.containerAttr = 'data-pjax-container'

  // go go go
  manageBehaviors(Behaviors)

  // on resize, check
  resized()

  // AOS
  AOS.init()

  // link being clicked
  Barba.Dispatcher.on('linkClicked', function (el) {
    // if we are coming to the same page that is behind the search
    if (Barba.HistoryManager.currentStatus().url === Barba.Pjax.getHref(el)) {
      triggerCustomEvent(document, 'search:hide')
    }
    A17.latestClickElement = el
  })

  // Init Transitions : pick the right one
  manageTransitions()

  // Reload behaviors after page finished loading
  Barba.Dispatcher.on('transitionCompleted', function (currentStatus) {
    // Update Header highlighted section to reflect current status
    const status = Barba.HistoryManager.currentStatus()
    if (status.namespace) {
      updateHeader(status.namespace)
    }

    // Is it the first load or do we already moved from another page ?
    if (Barba.HistoryManager.prevStatus()) {
      window.scroll(0, 0)

      A17.searched = false
      A17.beforeSearchContent = null
      A17.latestClickElement = null

      const container = document.querySelector(
        '[' + Barba.Pjax.Dom.containerAttr + ']'
      )
      if (container) {
        triggerCustomEvent(document, 'page:updated')
      }
    }

    AOS.refresh()
  })

  // Init Barba
  if (document.querySelector('[' + Barba.Pjax.Dom.wrapperAttr + ']')) {
    Barba.Pjax.start()
  }

  analytics()
})

// make console.log safe
if (typeof console === 'undefined') {
  window.console = {
    log: function () {
      return
    },
  }
}
